import { GameGlobalVarsRecord, getGameConfig, getGameGlobalVars, IGameConfig, IListItem } from "@/api";
import { InfoCard, Loader, LoaderSimple } from "@/components";
import { getSessionStorage } from "@/helpers";
import { GAME_CONFIG_SS_KEY, PRE_INITAL_ROUND } from "@/helpers/constants";
import { Datum, ResponsiveLine, Serie } from "@nivo/line";
import axios from "axios";
import React from "react";

type GlobalVarsHistoryItem = {
	round: string;
	n_proy?: string;
	[key: string]: number | string;
};

const getCurrentVarValues = async (gameId: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/globalVars/status/${gameId}`);
	return response.data as IListItem[];
};
const getHistoryValues = async (gameId: string) => {
	const response = await axios.get(`https://api.dev.r-manager.io/game-engine/globalVars/history/${gameId}`);
	return response.data as GlobalVarsHistoryItem[];
};

// TODO: this should come from a service
const getGoals = (gameId: string): Record<string, number> => {
	const bidGameIds = ["94d6760a-aaf8-446b-a37d-354789b58460", "ca64cfe1-e4cc-43cf-b47b-ecef9f7a5d30"];
	if (bidGameIds.indexOf(gameId) >= 0) {
		return {
			BRP: 100,
			TDP: 14,
			VAN: 60000,
		};
	}
	return {
		IDH: 85,
		C: 500,
		L: 95,
		PP: 300,
	};
	// return {
	// 	BRP: 100,
	// 	TDP: 14,
	// 	VAN: 60000,
	// };
};

// TODO: we should get these from a service
// Las metas son idh 85, C500, L 95, PP 300
const N_PROY_GOAL = 70;
// const GOALS: Record<string, number> = {
// 	IDH: 85,
// 	C: 500,
// 	L: 95,
// 	PP: 300,
// };

// const GOALS: Record<string, number> = {
// 	BRP: 85,
// 	TDP: 500,
// 	VAN: 95,
// };

function GlobalVarCard({ item, onClick, gameId }: { item: IListItem; onClick: (tag: string) => void; gameId: string }) {
	const goals = getGoals(gameId);
	return (
		<div className="rounded-md flex flex-col cursor-pointer hover:bg-gray-100 p-4" onClick={() => onClick(item.tag)}>
			<div className="flex justify-between">
				<div className="font-semibold text-gray-800">{item.tag}</div>
				<div className="text-sm text-green-800">{`${(((+item.value ?? 0) * 100) / goals[item.tag]).toFixed(2)}%`}</div>
			</div>
			<div className="text-sm text-gray-500">{item.text}</div>
			<div className="text-lg font-bold text-gray-800">{(+item.value).toFixed(2)}</div>
		</div>
	);
}

type NivoData = {
	data: Serie[];
	axisLeftLabel: string;
};

function getRound0HistoryRecord(globalVarsRecord: GameGlobalVarsRecord): GlobalVarsHistoryItem | undefined {
	if (!globalVarsRecord || !globalVarsRecord.items) {
		return;
	}
	const { items } = globalVarsRecord;
	const historyItem: GlobalVarsHistoryItem = {
		round: PRE_INITAL_ROUND,
	};
	items.forEach((gv) => (historyItem[gv.id] = gv.value));
	return historyItem;
}

export function GlobalVarsSection({ gameConfig }: { gameConfig: IGameConfig }) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [globalVars, setGlobalVars] = React.useState<IListItem[]>();
	const [globalVarsHistory, setGlobalVarsHistory] = React.useState<GlobalVarsHistoryItem[]>();
	const [nivoData, setNivoData] = React.useState<NivoData>({ data: [], axisLeftLabel: "Loading" });
	const [currentVarId, setCurrentVarId] = React.useState<string>();
	const loadData = async () => {
		try {
			setIsLoading(true);
			const currentGlobalVars = await getCurrentVarValues(gameConfig.pk);
			setGlobalVars(currentGlobalVars);

			const historyData = await getHistoryValues(gameConfig.pk);
			// Add the round 0 values
			const gameGlobalVars = await getGameGlobalVars(gameConfig.pk);
			setCurrentVarId(gameGlobalVars.items[0].id);
			console.info(gameGlobalVars);
			const round0HistoryRecord = getRound0HistoryRecord(gameGlobalVars);
			historyData.unshift(round0HistoryRecord);
			setGlobalVarsHistory(historyData);
		} catch (error) {
			console.error("Error retrieving the data", error);
		} finally {
			setIsLoading(false);
		}
	};
	React.useEffect(() => {
		if (globalVarsHistory && globalVars) {
			const dataPoints: Datum[] = globalVarsHistory
				.map((item) => {
					if (item[currentVarId] !== undefined) {
						return {
							x: item.round,
							y: item[currentVarId],
						};
					}
				})
				.filter(Boolean);
			setNivoData({
				data: [
					{
						id: currentVarId,
						data: dataPoints,
					},
				],
				axisLeftLabel: globalVars.find((cgv) => cgv.tag === currentVarId).text,
			});
		}
	}, [globalVars, globalVarsHistory, currentVarId]);
	React.useEffect(() => {
		loadData();
	}, []);
	if (isLoading || !currentVarId) {
		return <LoaderSimple message="Loading..." />;
	}
	const onVarClick = (tag: string) => {
		setCurrentVarId(tag);
	};
	const goals = getGoals(gameConfig.pk);
	return (
		<div className="mt-6">
			<div className={`grid grid-cols-${globalVars.length} gap-4 mb-2`}>
				{/* <InfoCard title="Último Round" subtitle="2030" />
				<InfoCard title="Proyectos" subtitle={firstGlobalVar?.value ?? "NA"} />
				<InfoCard title="% Cumplimiento" subtitle={`${(((+firstGlobalVar?.value ?? 0) * 100) / N_PROY_GOAL).toFixed(2)}%`} /> */}
				{globalVars.map((gv) => (
					<GlobalVarCard key={gv.tag} item={gv} onClick={onVarClick} gameId={gameConfig.pk} />
				))}
			</div>
			<div className="w-full h-60 py-4">
				<ResponsiveLine
					data={nivoData.data}
					useMesh={true}
					margin={{
						top: 20,
						right: 50,
						bottom: 20,
						left: 50,
					}}
					axisTop={undefined}
					axisLeft={{
						tickRotation: 0,
						tickSize: 6,
						tickValues: 5,
						legend: nivoData.axisLeftLabel,
						legendOffset: -40,
						legendPosition: "middle",
						format: (v: number) => `${v}`,
					}}
					pointSize={15}
					pointColor="#fff"
					pointBorderWidth={4}
					pointBorderColor="#000"
					lineWidth={5}
					tooltip={(props) => {
						return (
							<div className="border p-2 text-sm bg-white">
								<p>
									<span className="font-bold">{nivoData.axisLeftLabel}:</span> {(+props.point.data.y).toFixed(2)}
								</p>
								<p>
									<span className="font-bold">% Cumplimiento:</span> {`${((+props.point.data.y * 100) / goals[props.point.serieId]).toFixed(2)}%`}
								</p>
							</div>
						);
					}}
				/>
			</div>
		</div>
	);
}
